import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import classes from './Label.module.css'

const Label = ({className, ...props}) => (
  <label
    {...props}
    className={classnames(className, classes.label)}
  />
)

Label.propTypes = {
  className: PropTypes.string
}

export default Label
