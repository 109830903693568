import React from 'react'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import http from 'http-client'
import {Formik} from 'formik'

import {Col, Grid, Row} from 'react-flexbox-grid'
import {NamespacesConsumer} from 'react-i18next'

import Alert from 'components/common/Alert'
import Input from 'components/common/Input'
import Label from 'components/common/Label'
import Textarea from 'components/common/Textarea'
import FormGroup from 'components/common/FormGroup'
import ReCAPTCHA from 'components/common/ReCAPTCHA'
import SubmitButton from 'components/common/SubmitButton'

import classes from './Form.module.css'

class Form extends React.Component {
  static propTypes = {
    forwardedRef: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)

    this.recaptchaRef = React.createRef()

    this.state = {}
  }

  handleSubmit = (values, actions) => {
    this.setState({values, actions})
    this.recaptchaRef.current.execute()
  }

  handleCaptchaChange = captchaResponse => {
    const {values, actions} = this.state

    http.post(`/contact?captchaResponse=${captchaResponse}`, values)
      .then(() => {
        actions.resetForm()
        actions.setSubmitting(false)

        this.recaptchaRef.current.reset()

        this.setState({
          alertColor: 'success',
          alertMessage: 'ContactPanel.Form.submitSuccess'
        })
      })
      .catch(({response = {data: {}}}) => {
        const {errors = []} = response.data

        actions.setSubmitting(false)

        this.recaptchaRef.current.reset()

        for (let i = 0; i < errors.length; i++) {
          this.setState({
            alertColor: 'danger',
            alertMessage: [
              `ContactPanel.Form.serverErrors.${errors[i].code}`,
              'ContactPanel.Form.submitFailure'
            ]
          })
          return
        }

        this.setState({
          alertColor: 'danger',
          alertMessage: 'ContactPanel.Form.submitFailure'
        })
      })
  }

  render () {
    const {forwardedRef} = this.props
    const {alertColor, alertMessage} = this.state

    return (
      <NamespacesConsumer>
        {
          t => (
            <div ref={forwardedRef}>
              <Alert color={alertColor}>
                {t(alertMessage)}
              </Alert>
              <Formik
                initialValues={{
                  name: '',
                  email: '',
                  message: ''
                }}

                validationSchema={Yup.object().shape({
                  name: Yup.string().required(t('ContactPanel.Form.validationErrors.requiredName')),
                  email: Yup.string().email(t('ContactPanel.Form.validationErrors.invalidEmail')).required(t('ContactPanel.Form.validationErrors.requiredEmail')),
                  message: Yup.string().required(t('ContactPanel.Form.validationErrors.requiredMessage'))
                })}

                onSubmit={this.handleSubmit}

                render={({values, errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit}) => (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <Grid>
                      <Row middle='xs' around='xs'>
                        <Col md={5}>
                          <FormGroup error={errors.name} touched={touched.name}>
                            <Label htmlFor='name'>
                              {t('ContactPanel.Form.nameLabel')}
                            </Label>
                            <Input
                              id='name'
                              name='name'
                              type='text'
                              value={values.name}
                              placeholder={t('ContactPanel.Form.namePlaceholder')}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </FormGroup>

                          <FormGroup error={errors.email} touched={touched.email}>
                            <Label htmlFor='email'>
                              Email
                            </Label>
                            <Input
                              id='email'
                              name='email'
                              type='email'
                              value={values.email}
                              placeholder='you@example.com'
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={5}>
                          <FormGroup error={errors.message} touched={touched.message} style={{minHeight: 200}}>
                            <Label htmlFor='message'>
                              {t('ContactPanel.Form.messageLabel')}
                            </Label>
                            <Textarea
                              style={{minHeight: 150}}
                              id='message'
                              name='message'
                              type='textarea'
                              value={values.message}
                              placeholder={t('ContactPanel.Form.messagePlaceholder')}
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row middle='xs' around='xs'>
                        <Col md={11}>
                          <SubmitButton disabled={isSubmitting}>
                            {t('ContactPanel.Form.submitButton')}
                          </SubmitButton>
                        </Col>
                      </Row>
                    </Grid>
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      onChange={this.handleCaptchaChange}
                    />
                  </form>
                )}
              />
            </div>
          )
        }
      </NamespacesConsumer>
    )
  }
}

export default React.forwardRef((props, ref) => (
  <Form {...props} forwardedRef={ref} />
))
