import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import classes from './FormGroup.module.css'

const FormGroup = ({error, touched, children, className, ...props}) => {
  const showError = error && touched

  return (
    <div
      {...props}
      className={classnames(
        className,
        classes.formGroup,
        showError ? classes.errorChildren : ''
      )}
    >
      {children}
      {showError && <div className={classes.errorLabel}>{error}</div>}
    </div>
  )
}

FormGroup.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default FormGroup
