import React from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

export default React.forwardRef((props, ref) => (
  <ReCAPTCHA
    ref={ref}
    size='invisible'
    sitekey='6LduYW0UAAAAALLW61KRx1RJy2NJOcM986IQcZJU'
    {...props}
  />
))
