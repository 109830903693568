import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/common/Button'

import classes from './SubmitButton.module.css'

const SubmitButton = props => (
  <Button {...props} type='submit' disabled={props.disabled} className={classes.submit}>
    {props.children}
  </Button>
)

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired
}

export default SubmitButton
