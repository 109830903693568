import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import classes from './Textarea.module.css'

const Textarea = ({className, ...props}) => (
  <textarea
    {...props}
    className={classnames(className, classes.textarea)}
  />
)

Textarea.propTypes = {
  className: PropTypes.string
}

export default Textarea
