import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import classes from './Alert.module.css'

const colors = {
  danger: classes.alertDanger,
  success: classes.alertSuccess
}

const Alert = ({color, className, ...props}) => (
  <div
    {...props}
    className={classnames(className, classes.alert, colors[color])}
  />
)

Alert.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string
}

export default Alert
