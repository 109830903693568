import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import classes from './Button.module.css'

const Button = ({className, ...props}) => (
  <button
    {...props}
    className={classnames(className, classes.button)}
  />
)

Button.propTypes = {
  className: PropTypes.string.isRequired
}

export default Button
