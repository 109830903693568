import React from 'react'
import PropTypes from 'prop-types'

import dot from './dot.svg'

export const DotBackground = ({children}) => (
  <div style={{padding: '30px 0 10px', background: `url("${dot}") center 1%`}}>
    {children}
  </div>
)

DotBackground.propTypes = {
  children: PropTypes.node.isRequired
}

export default DotBackground
