import axios from 'axios'

const instance = axios.create({
  baseURL: '/api/v1',
  responseType: 'json'
})

instance.interceptors.request.use(config => {
  if (window.ga) {
    window.ga('send', 'event', {
      eventCategory: 'HTTP Requests',
      eventAction: config.method,
      eventLabel: config.baseURL + config.url
    })
  }

  return config
})

export default instance
