import React from 'react'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'
import {withNamespaces} from 'react-i18next'

import Layout from 'components/Layout'
import ContactPanel from 'components/ContactPanel'

import contact from './contact.jpg'

export const ContactPage = ({t, navigate, location}) => (
  <Layout center={false} navigate={navigate} location={location}>
    <Helmet
      title={t('ContactPage.meta.title')}
      meta={[
        {
          name: 'description',
          content: 'ContactPage.meta.description'
        },
        {
          name: 'keywords',
          content: 'ContactPage.meta.keywords'
        }
      ]}
    />
    <img src={contact} />
    <ContactPanel location={location} />
  </Layout>
)

ContactPage.propTypes = {
  t: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default withNamespaces()(ContactPage)
