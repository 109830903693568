import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import classes from './Input.module.css'

const Input = ({className, ...props}) => (
  <input
    {...props}
    className={classnames(classes.input, className)}
  />
)

Input.propTypes = {
  className: PropTypes.string
}

export default Input
