import React from 'react'
import PropTypes from 'prop-types'

import {withNamespaces} from 'react-i18next'
import {Grid, Row, Col} from 'react-flexbox-grid'
import {FaChevronCircleDown} from 'react-icons/fa'

import {colors} from 'utils/styles'
import DotBackground from 'components/common/DotBackground'

import Form from './Form'
import classes from './ContactPanel.module.css'

export class ContactPanel extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  }

  contact = React.createRef()

  handleClick = () => {
    this.contact.current.scrollIntoView({behavior: 'smooth'})
  }

  componentDidMount () {
    if (this.props.location.hash === '#form') {
      this.contact.current.scrollIntoView()
    }
  }

  render () {
    const {t} = this.props

    return (
      <DotBackground>
        <Grid>
          <Row center='xs' around='xs'>
            <Col md={11}>
              <div className={classes.title}>
                {t('ContactPanel.title')}
              </div>
              <div className={classes.content}>
                {t('ContactPanel.content')}
              </div>
            </Col>
          </Row>

          <Row center='xs' around='xs'>
            <Col md={11}>
              <FaChevronCircleDown
                size={45}
                style={{color: colors.brand, cursor: 'pointer'}}
                onClick={this.handleClick}
              />
            </Col>
          </Row>

          <Row middle='xs' around='xs'>
            <Col md={13}>
              <Form ref={this.contact} />
            </Col>
          </Row>
        </Grid>
      </DotBackground>
    )
  }
}

export default withNamespaces()(ContactPanel)
